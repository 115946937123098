import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Manage.vue'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manage',
    component: () => import('../views/Manage.vue'),
    redirect: "/Login",
    children: [

      {
        path: 'home',
        name: '首页',
        component: () => import('../views/Home.vue'),
      },
      {
        path: 'user',
        name: '会员列表',
        component: () => import('../views/User.vue'),
      },

      {
        path: 'recharge',
        name: '充值记录',
        component: () => import('../views/Recharge.vue')
      },
      {
        path: 'withdrawal',
        name: '提现记录',
        component: () => import('../views/Withdrawal.vue')
      },
      {
        path: 'flow',
        name: '钱包流水记录',
        component: () => import('../views/Flow.vue')
      },
      {
        path: 'usdt',
        name: 'USDT地址记录',
        component: () => import('../views/Usdt.vue')
      },
      {
        path: 'sckill',
        name: '抢单界面',
        component: () => import('../views/Sckill.vue')
      },
      {
        path: 'setapp',
        name: 'APP设置',
        component: () => import('../views/Setapp.vue')
      },
      {
        path: 'setproduct',
        name: '产品阙值',
        component: () => import('../views/Setproduct.vue')
      },
      {
        path: 'setagent',
        name: '代理佣金设置',
        component: () => import('../views/Setagent.vue')
      },
      {
        path: 'setnotice',
        name: '公告设置',
        component: () => import('../views/Setnotice.vue')
      },
      {
        path: 'settakeorder',
        name: '抢单设置',
        component: () => import('../views/Settakeorder.vue')
      },
      {
        path: 'customerservice',
        name: '在线客服设置',
        component: () => import('../views/Customerservice.vue')
      },
      {
        path: 'product',
        name: '产品管理',
        component: () => import('../views/Product.vue')
      },
      {
        path: 'setvip',
        name: 'VIP等级设置',
        component: () => import('../views/setvip.vue')
      },
      {
        path: 'rotation',
        name: '轮播图设置',
        component: () => import('../views/rotation.vue')
      },
      {
        path: 'continuousOrder',
        name: '连单设置',
        component: () => import('../views/continuousOrder.vue')
      },{
        path: 'dlReport',
        name: '全民代理列表',
        component: () => import('../views/dlReport.vue')
      },{
        path: 'ctReport',
        name: '充提报表',
        component: () => import('../views/ctReport.vue')
      },{
        path: 'taskReport',
        name: '任务报表',
        component: () => import('../views/taskReport.vue')
      },{
        path: 'changePwd',
        name: '修改密码',
        component: () => import('../views/changePwd.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },

  {
    path: '/about',
    name: 'About',

    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/test',
    name: 'Test',

    component: () => import('../views/Goods.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
  localStorage.setItem("currentPathName", to.name)
  store.commit("setPath")
  next()
})


export default router

<template>
	<div style="font-size: 12px; line-height: 60px; display: flex">
		<div style="flex: 1; font-size: 18px">
			<span :class="collapseBtnClass" style="cursor: pointer" @click="collapse">
			</span>
			<el-breadcrumb separator="/" style="display: inline-block; margin-left: 10px">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>{{ currentPathName }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="txBtn" @click="goTixian()">
			提现<i>{{ count }}</i>
		</div>
		<el-dropdown style="width: 150px; cursor: pointer; text-align: right">
			<div style="display: inline-block; transform: translateX(-30px)">
				<img :src="user.avatarUrl" alt="" style="
            height: 20px;
            width: 20px;
            border-radius: 50%;
            position: relative;
            top: 6px;
            right: 5px;
          " />
				<span>{{ user.userNickname }}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>
			</div>
			<el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
				<el-dropdown-item style="font-size: 14px; padding: 5px 0">
					<router-link to="/person">个人信息</router-link>
				</el-dropdown-item>
				<el-dropdown-item style="font-size: 14px; padding: 5px 0">
					<router-link to="/changePwd">修改密码</router-link>
				</el-dropdown-item>
				<el-dropdown-item style="font-size: 14px; padding: 5px 0">
					<span style="text-decoration: none" @click="loginout">退出</span></el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
	export default {
		name: "Header",
		props: {
			collapseBtnClass: String,
			user: Object,
			// collapse: Boolean,
		},
		computed: {
			currentPathName() {
				return this.$store.state.currentPathName; //需要监听的数据
			},
			// currentUser() {
			//     return this.$store.state.user;　　//需要监听的数据
			// },
		},
		watch: {
			currentPathName(newVal, oldVal) {
				console.log(newVal);
			},
			// currentUser(newVal, oldVal) {

			//     this.user = newVal
			//     console.log("++++++++++++++++++++=====" + this.user)
			// }
		},
		data() {
			return {
				count: 0,
				// user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
			};
		},
		methods: {
			goTixian() {
				this.$router.push("/withdrawal");
			},
			collapse() {
				// this.$parent.$parent.$parent.$parent.collapse()  // 通过4个 $parent 找到父组件，从而调用其折叠方法
				this.$emit("asideCollapse");
			},
			loginout() {
				this.$router.push("/login");

				localStorage.removeItem("user");
				// this.$store.commit("logout")
				this.$message.success("退出成功");
			},
		},
		mounted() {
			setInterval(() => {
				this.request.get("api/en/super_admin/balance/waitCount").then((res) => {
					this.count = res.data;
				});
			}, 2000);
		},
	};
</script>

<style scoped>
	.txBtn {
		padding: 0 10px;
		text-align: center;
		position: relative;
		cursor: pointer;
	}

	.txBtn i {
		display: inline-block;
		width: 15px;
		height: 15px;
		line-height: 15px;
		text-align: center;
		background: #db2c2c;
		border-radius: 30px;
		color: #fff;
		font-style: normal;
		font-size: 12px;
		position: absolute;
		top: 10px;
		right: 0;
	}
</style>
<template>
  <el-menu
    :default-openeds="['1', '3']"
    style="height: 100%; overflow-x: hidden"
    background-color="rgb(48,65,86)"
    text-color="#fff"
    active-text-color="#ffd04b"
    :collapse-transition="false"
    :collapse="isCollapse"
    router
  >
    <div style="height: 60px; line-height: 60px; text-align: center">
      <img
        src="../assets/logo.png"
        alt=""
        style="width: 20px; position: relative; top: 5px"
      />
      <b style="color: white" v-show="LogoTextShow">后台管理系统</b>
    </div>

    <el-menu-item index="/home">
      <template slot="title">
        <i class="el-icon-house"></i>
        <span slot="title">首页</span>
      </template>
    </el-menu-item>

    <el-submenu index="2">
      <template slot="title"
        ><i class="el-icon-menu"></i>
        <span slot="title">会员中心</span>
      </template>

      <el-menu-item index="/user">
        <template slot="title"
          ><i class="el-icon-s-custom"></i>
          <span slot="title">会员列表</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/recharge">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">充值记录</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/withdrawal">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">提现记录</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/flow">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">钱包流水记录</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/usdt">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">USDT地址记录</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/sckill">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">抢单记录</span>
        </template>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="5">
      <template slot="title"
        ><i class="el-icon-menu"></i>
        <span slot="title">代理中心</span>
      </template>
      <el-menu-item index="/dlReport">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">全民代理列表</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/ctReport">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">充提报表</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/taskReport">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">任务报表</span>
        </template>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="3">
      <template slot="title"
        ><i class="el-icon-menu"></i>
        <span slot="title">APP管理</span>
      </template>
      <el-menu-item index="/product">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">产品管理</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/setproduct">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">产品阙值</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/setagent">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">代理佣金设置</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/setnotice">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">公告设置</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/settakeorder">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">抢单设置</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/setapp">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">APP设置</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/setvip">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">VIP等级设置</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/rotation">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">轮播图设置</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/customerservice">
        <template slot="title"
          ><i class="el-icon-document"></i>
          <span slot="title">在线客服设置</span>
        </template>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>



<script>
export default {
  name: "Aside",
  props: {
    isCollapse: Boolean,
    LogoTextShow: Boolean,
  },
};
</script>